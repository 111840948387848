import React, { useState, useEffect, useRef } from 'react';
import SVGA from 'svgaplayerweb';

import './HomePageFif.scss';

export default function HomePageFif(props) {
  const { enterAnimation, action, pageTabActive, fontSize, isSmallScreen } = props;
  const courseList = [
    {
      year: '2023', child: [
        { position: 1, month: 1, text: '荣登2022年武汉市重点税源企业光荣榜' },
        { position: 2, month: 2, text: 'CEO施其明荣获“光谷工匠企业家”' },
        { position: 3, month: 3, text: '担任上海区块链技术协会理事单位' },
        { position: 4, month: 3, text: '担任湖北软件协会理事单位' },
        { position: 5, month: 5, text: '荣获2023年出版融合发展优秀联络员单位' },
        { position: 6, month: 6, text: '荣获第十五届“全国文化企业30强”提名企业' },
        { position: 7, month: 9, text: '湖北省上市后备金文种子企业' },
        { position: 8, month: 9, text: '武汉市现代服务业政策奖补企业' },
        { position: 9, month: 9, text: '2023年武汉东湖高新区独角兽企业' }
      ]
    },
    {
      year: '2022', child: [
        { position: 1, month: 1, text: 'RAYS入选2021-2022年度武汉市优秀高新技术项目' },
        { position: 2, month: 1, text: '2021-2022年度武汉市高新技术企业' },
        { position: 3, month: 2, text: '认定为中国光谷经济发展突出贡献企业' },
        { position: 4, month: 5, text: '入选湖北省2021-2022年度上市后备金种子企业' },
        { position: 5, month: 6, text: '完成D+轮融资' },
        { position: 6, month: 8, text: '荣获工业和信息化部第四批专精特新“小巨人”企业' },
        { position: 7, month: 11, text: '获批出版领域首家教育部工程中心共建单位' },
        { position: 8, month: 11, text: '荣获“湖北省科创新物种——潜在独角兽企业”' },
        { position: 9, month: 12, text: '第二届湖北省文化企业十强' }
      ]
    },
    {
      year: '2021', child: [
        { position: 1, month: 2, text: '获批“国家新闻出版署融合出版智能服务技术与标准重点实验室”', longlongText: true },
        { position: 2, month: 6, text: '武汉未来之星TOP100企业' },
        { position: 3, month: 7, text: '首批武汉雄鹰企业' },
        { position: 4, month: 8, text: '2021年武汉服务业企业百强；武汉首批线上经济重点企业', longText: true },
        { position: 5, month: 9, text: '2021-22年湖北省上市后备“金文种子”；2021年武汉新经济政策奖补企业', longlongText: true },
        { position: 6, month: 10, text: 'RAYS入选全国新闻出版深度融合发展创新案例；湖北省服务业百强', longText: true },
        { position: 7, month: 11, text: '武汉市2021年上市后备金种子企业' },
        { position: 8, month: 12, text: '湖北省2021年专精特新“小巨人”企业；入选《中国文化消费投资发展报告（2021）》', longlongText: true },
        { position: 9, month: 12, text: '荣获“国家新闻出版署科技应用示范单位”' }
      ]
    },
    {
      year: '2020', child: [
        { position: 1, month: 1, text: '完成D轮融资' },
        { position: 2, month: 2, text: '荣获“武汉东湖高新首批科技企业创新案例技术产品清单”', longText: true },
        { position: 4, month: 3, text: '湖北省科学进步奖；RAYS6.0上线，推出书童小睿智能阅读助手', longText: true },
        { position: 6, month: 7, text: '总裁白立华荣获第四届湖北出版政府奖' },
        { position: 7, month: 7, text: 'RAYS 入选国家工信部《中国信息消费助力疫情防控案例》', longText: true },
        { position: 8, month: 9, text: '武汉市软件企业百强' },
        { position: 9, month: 11, text: '首届“湖北文化企业十强”' }
      ]
    },
    {
      year: '2019', child: [
        { position: 1, month: 1, text: '被评为大数据企业20强' },
        { position: 3, month: 4, text: 'RAYS5.0上线，场景化运营纸书' },
        { position: 4, month: 6, text: '中国新闻科技类最高奖王选奖一等奖' },
        { position: 5, month: 7, text: '被评为2019武汉市软件百强企业' },
        { position: 6, month: 8, text: '数传集团西南出版融合运营中心落地重庆' },
        { position: 9, month: 9, text: '获批“湖北省博士后创新实践基地”' }
      ]
    },
    {
      year: '2018', child: [
        { position: 1, month: 1, text: '2017年中国光谷互联网+最具商业价值奖' },
        { position: 2, month: 1, text: '荣获“中国光谷互联网+示范应用项目”' },
        { position: 3, month: 1, text: '获批“国家出版融合数据共享研发基地”' },
        { position: 4, month: 3, text: '入选《2018胡润中国最具投资价值新星企业百强榜》' },
        { position: 5, month: 5, text: '“世界大学女校长论坛”协办单位；获批“湖北省企业技术中心”', longlongText: true },
        { position: 6, month: 7, text: '2017-2018数字出版 优秀展示单位;数传上海子公司正式成立', longText: true },
        { position: 7, month: 11, text: '被评为ISLI国家标准应用技术支持单位' },
        { position: 8, month: 11, text: '获批“湖北省企业技术中心”' },
        { position: 9, month: 11, text: 'RAYS4.0上线，推出“主题书”策略' }
      ]
    },
    {
      year: '2017', child: [
        { position: 1, month: 1, text: '被评为湖北新闻出版广电产业重点企业' },
        { position: 2, month: 2, text: 'RAYS蓝海版本上线' },
        { position: 3, month: 4, text: '2016年度湖北省双创战略团队' },
        { position: 4, month: 5, text: '顺利通过CMMI3级认证' },
        { position: 6, month: 6, text: 'RAYS3.0上线，纸书与读者服务相契合' },
        { position: 7, month: 7, text: '中国数字出版创新论坛数字出版创新奖' },
        { position: 8, month: 9, text: '董事长刘永坚荣获第三届湖北出版政府奖' },
        { position: 9, month: 11, text: '完成C轮融资' }
      ]
    },
    {
      year: '2016', child: [
        { position: 1, month: 4, text: '2015年度湖北省双创战略团队' },
        { position: 2, month: 7, text: '国家双软企业认证' },
        { position: 3, month: 8, text: '荣获2014-2016年推动媒体融合十佳传媒企业；RAYS2.0上线，培养读者扫码习惯', longText: true, longleaf4: true },
        { position: 4, month: 10, text: '资本持续注入完成B轮融资4500万元' },
        { position: 5, month: 10, text: '湖北省唯一入选国家新闻出版广电总局《2016新闻出版业数字化转型升级软件技术服务商推荐名录》的企业', superlongText: true },
        { position: 6, month: 10, text: '数字化转型升级软件技术服务商；武汉市东湖高新区第九批“3551光谷人才计划”', longText: true },
        { position: 7, month: 11, text: '国家新闻出版广电总局首批20个出版融合发展重点实验室建设单位', longText: true },
        { position: 8, month: 11, text: '产品RAYS被评为“武汉市创新产品”' },
        { position: 9, month: 11, text: '湖北省新闻出版广电产业“双百工程”示范企业' }
      ]
    },
    {
      year: '2015', child: [
        { position: 1, month: 8, text: 'RAYS1.0上线，创立“现代纸书”' },
        { position: 4, month: 10, text: '国家高新技术企业认证' },
        { position: 5, month: 11, text: '国家新闻出版广电总局专业数字内容资源知识服务模式试点工作技术支持单位，知识服务与运营技术支持单位第一名', superlongText: true },
        { position: 9, month: 12, text: '获得资本青睐完成A轮融资1500万元' },
        { position: 8, month: 12, text: '北京子公司成立' }
      ]
    },
    {
      year: '2014', child: [
        { position: 1, month: 3, text: '武汉理工数字传播工程有限公司成立' },
        { position: 4, month: 3, text: '湖北省工程技术研究中心' },
        { position: 5, month: 6, text: '“国家数字传播工程创新人才培养基地”' },
        { position: 9, month: 12, text: 'RAYS诞生' }
      ]
    }
  ];

  const startAllAnimation = useRef(false);
  const player = useRef(null);
  const parser = useRef(null);
  const player2 = useRef(null);
  const parser2 = useRef(null);
  const [course, setCourse] = useState(courseList[0]);
  const [tabIndex, setTabIndex] = useState(0);
  const [tipsOpen, setTipsOpen] = useState(true);
  const [leafShow, setLeafShow] = useState(false);
  const [leaveShow, setLeaveShow] = useState(false);
  const [startLive, setstartLive] = useState(false);

  useEffect(() => {
    if(enterAnimation && (pageTabActive === 4 || isSmallScreen)) {
      parser.current && player.current.startAnimation();
    }
  }, [enterAnimation, pageTabActive, isSmallScreen]);

  function onChangeYear(value, index) {
    if(tabIndex === index) {
      return;
    }
    setLeafShow(false);
    setLeaveShow(true);
    setstartLive(true);
    player2.current && player2.current.startAnimation(false);
    setTimeout(() => {
      setLeaveShow(false);
      setTabIndex(index);
      setTipsOpen(false);
      let arr = courseList.filter(v => Number(v.year) === value) || [];
      setCourse(arr[0]);
    }, 500);
  }

  useEffect(() => {
    player.current = new SVGA.Player('#animate');
    parser.current = new SVGA.Parser('#animate');
    player2.current = new SVGA.Player('#animate2');
    parser2.current = new SVGA.Parser('#animate2');
    parser.current.load('https://cdn.5rs.me/font/shu3zheng.svga?a=2', function(videoItem) {
      player.current.loops = 1;
      player.current.clearsAfterStop = false;
      player.current.setVideoItem(videoItem);
      if(enterAnimation && (pageTabActive === 4 || isSmallScreen)) {
        startAllAnimation.current = false;
        player.current.startAnimation(false);
      }
    });
    parser2.current.load('https://cdn.5rs.me/font/shu3fan.svga?a=2', function(videoItem) {
      player2.current.loops = 1;
      player2.current.clearsAfterStop = true;
      player2.current.setVideoItem(videoItem);

    });
    // player.current.onFinished(() => {
    //     !leafShow && setLeafShow(true);
    // })
    player.current.onFrame((count) => {
      if(count >= 30) {
        setLeafShow(true);
      }
    });
    player2.current.onFinished(() => {
      setstartLive(false);
      player.current.startAnimation();
    });
  }, [fontSize]);

  useEffect(() => {
    if((pageTabActive === 5 && action === 'up') || (pageTabActive === 3 && action === 'down')) {
      setLeafShow(false);
      setLeaveShow(true);
    }
  }, [pageTabActive]);

  return <div className={`home-content-fif ${isSmallScreen && 'home-content-fif-m'} ${(enterAnimation && (isSmallScreen || pageTabActive === 4)) && 'enterAnimation'}`} id="temp5">
    <div className="home-content-fif-container">
      <div className="left">
        <div className="left-title"><img src="https://oss.5rs.me/oss/uploadfe/png/346de0abe5abbbd3d6b5a09e096262df.png" /></div>
        <div className="years-wrapper">
          <div className="btn-item">
            <img src="https://oss.5rs.me/oss/uploadfe/png/1012ffcca015bfeadd23abfa3222f0a5.png" />
          </div>
          <div
            className="years"
            style={{
              transform: `translateY(${1.2 - (tabIndex * 0.44)}rem)`
            }}
          >
            {YEAR_LIST.map((item, index) => <div
              className={`years-item item${index}` + (tabIndex === index ? ' active' : '')}
              onClick={() => onChangeYear(item.value, index)}
              key={index}
              style={{ opacity: `${1 - (Math.abs(index - tabIndex) * 0.1)}` }}
            >
              <div className="year">{item.year}</div>
            </div>)}
            <div className="click-tip">
              {tipsOpen && <div className="text">点击查看其他年度</div>}
            </div>
          </div>
        </div>

      </div>
      <div className="right">
        {course.child.map(item => (item.position % 2 !== 0 ?
          <div key={item.position} className={`courseleft course${item.position} ${item.longText && 'longText-left'} ${item.longlongText && 'longlongText-left'} ${item.longleaf4 && 'longleaf4'} ${item.superlongText && 'superlongText-left'} ${leafShow ? `doing${item.position}` : leaveShow ? `leave` : ''}`}>
            <div className="month"><span>{item.month}</span>月</div>
            <div className={`content ${item.longText && 'long-text'} ${item.longlongText && 'long-long-text'} ${item.superlongText && 'super-long-text'}`}><p>{item.text}</p></div>
            {/* <div className={`marker marker${item.position}`}></div> */}
          </div> :
          <div key={item.position} className={`courseright course${item.position} ${item.longText ? 'longText-right' : ''} ${item.longlongText ? 'longlongText-right' : ''} ${item.superlongText && 'superlongText-right'} ${leafShow ? `doing${item.position}` : leaveShow ? `leave` : ''}`}>
            <div className={`content ${item.longText && 'long-text'} ${item.longlongText && 'long-long-text'} {item.superlongText && 'super-long-text'}`}><p>{item.text}</p></div>
            <div className="month"><span>{item.month}</span>月</div>
            {/* <div className={`marker marker${item.position}`}></div> */}
          </div>))}
      </div>
      {/* {leafShow && <div id="animate" className="animate" />} */}
      {/* style={{ height: '760px', width: '766px'}} */}
      {/* style={{ height: `${ 7.46 * fontSize}px`, width: `${ 4.89 * fontSize}px`}} */}
      <div id="animate" style={{ height: `${6.8 * fontSize}px`, width: `${6.84 * fontSize}px`, left: `${3.7 * fontSize}px` }} className={`animate ${startLive && 'clipPath'}`} />
      <div id="animate2" style={{ height: `${6.8 * fontSize}px`, width: `${6.84 * fontSize}px`, left: `${3.7 * fontSize}px` }} className={`animate ${!startLive && 'clipPath'}`} />
    </div>
  </div>;
}

const YEAR_LIST = [
  { year: '2023', value: 2023 },
  { year: '2022', value: 2022 },
  { year: '2021', value: 2021 },
  { year: '2020', value: 2020 },
  { year: '2019', value: 2019 },
  { year: '2018', value: 2018 },
  { year: '2017', value: 2017 },
  { year: '2016', value: 2016 },
  { year: '2015', value: 2015 },
  { year: '2014', value: 2014 }
];