import { Helmet } from 'react-helmet';
import React from 'react';

export default function HelmetHeader() {
  return <Helmet>
    <title>数传集团——全球出版融合服务的引领者</title>
    <meta name={'description'} content="数传集团是国内领先的专注于帮助新闻出版机构出版融合转型升级的新闻出版大数据服务企业，提供面向于出版社开展的行业专属服务模式、编辑的一站式图书解决方案和围绕读者所开展的精准阅读服务体系，为全品类图书提供延展性数字内容及服务。集团创造性研发出了国内一流的媒体融合整体解决方案——RAYS，RAYS平台通过内容服务、阅读工具服务、兴趣及读书活动等领域提升纸书带给读者的延展性价值。" />
    <meta name={'keywords'} content={'数传集团、RAYS、出版社转型、武汉理工数字传播工程有限公司、扫码看书、纸书、纸书变现'} />
  </Helmet>;
}
