import React from 'react';
import HomePageOne from './components/HomePage/HomePageOne';
import HomePageTwo from './components/HomePage/HomePageTwo';
import HomePageThree from './components/HomePage/HomePageThree';
import HomePageFour from './components/HomePage/HomePageFour';
import HomePageFif from './components/HomePage/HomePageFif';
import HomePageSix from './components/HomePage/HomePageSix';
import HomePageSeven from './components/HomePage/HomePageSeven';
import AnimationPage from './components/HomePage/AnimationPage';
import Footer from '@/components/Footer/Footer';
import NavMenu from '@/components/NavMenu/NavMenu';
import Modal from '@/components/Modal/Modal';
import Login from '@/components/Login/Login';

import spider from '@/business/spider';
import Utils from '@/utils/utils';

// import "../styles/main.scss";
import './index.scss';
import HelmetHeader from './components/Helmet';

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTabActive: 0,
      pageTwoTextActive: 0,
      moveStatus: true,
      notMove: false,
      fontSize: Utils.setRemUnit(),
      isClick: false,
      enterAnimation: true,
      safeLoginLayer: false,
      action: 'up'
    };
    this.headerRef = React.createRef();
  }

  // IsPC = () => {
  //   let userAgentInfo = navigator.userAgent;
  //   let Agents = ["Android", "iPhone",
  //     "SymbianOS", "Windows Phone",
  //     "iPad", "iPod"];
  //   let flag = true;
  //   for(let v = 0; v < Agents.length; v++) {
  //     if(userAgentInfo.indexOf(Agents[v]) > 0) {
  //       flag = false;
  //       break;
  //     }
  //   }
  //   return flag;
  // }

  CallUsHandle = e => {
    this.sliderMenuRef.callUs();
  }

  onmousewheel = () => {
    //节流
    let endTime = new Date();
    window.onmousewheel = (e) => {
      if(this.isSmallScreen()) return;
      const { notMove, pageTabActive } = this.state;
      if(notMove) {
        return;
      }
      let newPageTabActive;
      let action;
      if(new Date() - endTime < 500) {
        return;
      }
      if(e.wheelDeltaY < 0) {
        if(pageTabActive === 7) return;
        action = 'up';
        newPageTabActive = pageTabActive + 1;
      } else {
        if(pageTabActive === 0) return;
        action = 'down';
        newPageTabActive = pageTabActive - 1;
      }
      this.setState({
        action,
        pageTabActive: newPageTabActive,
        notMove: true,
        isClick: false,
        leaveAnimation: true,
        enterAnimation: false
      });
      endTime = new Date();
    };
  }

  onChangePageTab = (index) => {
    const { pageTabActive, enterAnimation } = this.state;
    if(pageTabActive === index && enterAnimation) {
      return;
    }
    this.HomePage && (this.HomePage.style.transform = `translateY(-${(index) * 100}vh)`);
    this.setState({
      enterAnimation: true,
      leaveAnimation: false
    });
  }

  changePageTab = (index) => {
    const { pageTabActive } = this.state;
    let state = {
      notMove: true
    };
    this.HomePage && (this.HomePage.style.transform = `translateY(-${(index) * 100}vh)`);
    state = {
      ...state,
      isClick: true,
      action: pageTabActive > index ? 'down' : 'up',
      pageTabActive: index,
      leaveAnimation: false,
      enterAnimation: true
    };
    setTimeout(() => {
      this.setState({
        notMove: false
      });
    }, 1000);
    this.setState(state);
  }

  setAnimation = (animation) => {
    this.setState({
      enterAnimation: animation
    });
  }

  setMoveStatus = (moveStatus) => {
    let state = {
      moveStatus: moveStatus
    };
    if(!moveStatus) {
      state.notMove = false;
    }
    this.setState(state);
  }

  navigateTo = (url, showWindow) => {
    const { history } = this.props;
    if(showWindow) {
      window.open(url);
    } else {
      history.push(url);
    }
  }

  setSafeLoginLayer = (val) => {
    this.setState({
      safeLoginLayer: val
    });
  }

  isSmallScreen = () => Utils.isSmallScreen()

  // 浏览器视口宽度变化时，刷新全局fontSize
  handleViewWidthOnChange = () => {
    this.setState({
      fontSize: Utils.setRemUnit()
    });
  }

  onclickToPageTwo= (index) => {
    this.setState({ pageTabActive: index, enterAnimation: false });
  }

  componentDidMount = () => {
    if(Utils.getScreenWidth() < 1366) {
      const viewport =  document.querySelector('meta[name=viewport]');
      viewport.content = 'width=device-width,initial-scale=0.1,maximum-scale=1.0,user-scalable=yes';
    }
    this.onmousewheel();
    spider.init();
    const resizeEvt = 'orientationchange' in window ? 'orientataionchange' : 'resize';
    window.addEventListener(resizeEvt, this.handleViewWidthOnChange, false);
    if(window.location.hash) {
      setTimeout(() => {
        this.changePageTab(+window.location.hash.slice(1));
      });
    }
    window.addEventListener('hashchange', () => {
      if(window.location.hash) {
        this.changePageTab(+window.location.hash.slice(1));
      }
    });
  }

  componentWillUnmount = () => {
    window.removeEventListener('orientationchange', this.handleViewWidthOnChange);
    const viewport =  document.querySelector('meta[name=viewport]');
    viewport.content = 'width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no';
  }

  render() {
    const { pageTabActive, fontSize, enterAnimation, leaveAnimation, moveStatus, notMove, action, isClick, safeLoginLayer, pageTwoTextActive } = this.state;

    const commonProps = {
      action,
      leaveAnimation,
      enterAnimation,
      moveStatus,
      fontSize,
      pageTabActive,
      pageTwoTextActive,
      notMove,
      isClick,
      isSmallScreen: this.isSmallScreen()
    };
    return (
      <div className={['Page-Home', this.isSmallScreen() && 'Page-Home-m'].join(' ')} id="fullpage">
        {(this.state.pageTabActive !== 0 || this.isSmallScreen()) &&
          <NavMenu
            isBlueMenu={[4, 6].includes(this.state.pageTabActive)}
            notMove={notMove}
            pageTabActive={this.state.pageTabActive}
            onChangePageTab={this.changePageTab}
            isSmallScreen={this.isSmallScreen()}
          />
        }
        {pageTabActive === 0 && enterAnimation &&
        <div onClick={() => this.onclickToPageTwo(pageTabActive + 1)} className={`pilot  next-btn ${action === 'down' && 'down'}`}>
          <img src="https://oss.5rs.me/oss/uploadfe/png/fe301f251f0535184031d289f21fc6d8.png" />
        </div>
        }
        <HelmetHeader />
        <div className={`header-content ${enterAnimation && pageTabActive === 0 && 'enterAnimation'}`}>
          <div className="nav-logo">
            <a className={`logo transition-left`} href="/">
              <img src="https://oss.5rs.me/oss/uploadfe/png/48586f95084a5392fe7c40f75ea6b335.png" />
            </a>
            <div className={`nav transition-right`}>
              {NAV_LIST.map(item => <div className="nav-item" onClick={() => this.navigateTo(item.url ? item.url : '/', item.window)} key={item['content']}>{item.content}</div>)}
              <div className="login" onClick={() => this.setSafeLoginLayer(true)}>
                <img src="https://oss.5rs.me/oss/uploadfe/png/e11d333466c5207d6b76a341411fd2e0.png" />
              </div>
            </div>
          </div>
        </div>
        {this.isSmallScreen() && <div className="count-text-box"><div className={`count-text transition-right`} /></div> }
        <div className="content-page-content" style={{ zIndex: (([1, 2, 3, 4, 5, 6, 7].includes(pageTabActive) && enterAnimation) || (leaveAnimation)) ? 12 : 10 }}>
          <div className="content-page" ref={ref => this.HomePage = ref}>
            <HomePageOne {...commonProps} onCallUs={e => this.CallUsHandle()} />
            <HomePageTwo {...commonProps} />
            <HomePageThree {...commonProps} />
            <HomePageFour {...commonProps} />
            <HomePageFif {...commonProps} />
            <HomePageSix {...commonProps} />
            <HomePageSeven {...commonProps} />
            <Footer isIndex {...commonProps} onChangePageTab={this.changePageTab} />
          </div>
        </div>
        { !this.isSmallScreen() &&
          <AnimationPage
            setMoveStatus={this.setMoveStatus}
            setAnimation={this.setAnimation}
            isClick={isClick}
            action={action}
            enterAnimation={enterAnimation}
            onChangePageTab={this.onChangePageTab}
            pageTabActive={pageTabActive}
          />
        }
        {
          safeLoginLayer &&
          <Modal onModalClick={() => this.setSafeLoginLayer(false)}>
            <Login onModalClick={() => this.setSafeLoginLayer(false)} />
          </Modal>
        }
      </div>
    );
  }
}

const NAV_LIST = [
  { 'content': <span>首&nbsp;&nbsp;&nbsp;页</span>, 'url': '/' },
  { 'content': '产品服务', 'url': '/pdtserve' },
  { 'content': '案例平台', url: '/case' },
  { 'content': '新闻资讯', url: '/news' },
  {
    'content': '关于我们', 'url': '/aboutus', child: [
      { content: '关于我们', url: '/aboutus' },
      { content: '核心团队', url: '/coreteam' },
      { content: '人才招聘', url: 'https://app.mokahr.com/social-recruitment/dcrays/39604', window: true }

    ]
  },
  { 'content': '社会责任', 'url': '/csr' },
  { 'content': '人才招聘', url: 'https://app.mokahr.com/social-recruitment/dcrays/39604', window: true }
];