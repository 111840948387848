import { useSetState } from 'ahooks';
import React, { useEffect, useRef, useState } from 'react';
import { useSyncCallback } from '@/utils/hooks';
import './HomePageFour.scss';

export default function HomePageFour(props) {
  const { enterAnimation, action, pageTabActive, leaveAnimation, isSmallScreen } = props;
  const timer = useRef(null);
  const interval = useRef(null);
  const [count, setCount] = useState(0);
  const [circleList, setCircleList] = useState(IMG_LIST);
  const [textList, setTextList] = useState(TEXT_LIST);
  const isClick = useRef(false);
  const [state, setState] = useSetState({
    enterEnd: false
  });
  useEffect(() => {
    if (action === 'up') {
      if (enterAnimation && pageTabActive === 3) {
        setTimeout(() => {
          setState({
            enterEnd: true
          });
        }, 1000);
      }
    }
  }, [enterAnimation]);

  function onChangeIconCommon(index) {
    if (index === 0) {
      return;
    } else {
      if (isClick.current) return;
      isClick.current = true;
      setCount(index);
      let list = [...circleList]; //球数组
      let arr = [...textList]; //文本数组
      if (index === 1) { // 点击第二个球
        list.push(circleList[0]);
        arr.push(textList[0]);
        setCircleList(list);
        setTextList(arr);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          setCircleList(list.slice(1));
          setTextList(arr.slice(1));
          setCount(0);
          isClick.current = false;
        }, 1000);
      }
      if (index === 2) { // 点击第三个球
        list = list.concat([circleList[0], circleList[1]]);
        setCircleList(list);
        arr = arr.concat([textList[0], textList[1]]);
        arr.splice(1, 1, textList[2]);
        setTextList(arr);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          setCircleList(list.slice(2));
          setTextList(arr.slice(2));
          setCount(0);
          isClick.current = false;
        }, 1000);
      }
      if(isSmallScreen) {
        clearInterval(interval.current);
        interval.current = setInterval(() => {
          onChangeIcon();
        }, 5000);
      }
    }
  }

  const onChangeIcon = useSyncCallback((index = 1) => {
    onChangeIconCommon(index);
  });

  useEffect(() => {
    if(pageTabActive === 3 || isSmallScreen) {
      interval.current = setInterval(() => {
        onChangeIcon();
      }, 5000);
    } else {
      clearInterval(interval.current);
    }
    return () => {
      clearInterval(interval.current);
    };
  }, [pageTabActive, isSmallScreen]);

  return <div className={`home-content-four ${isSmallScreen && 'home-content-four-m'} ${(isSmallScreen || state.enterEnd) && 'enterEnd'} ${(enterAnimation && (isSmallScreen || pageTabActive === 3)) && 'enterAnimation'} ${leaveAnimation && pageTabActive !== 3 && 'leaveStart'}`} id="temp4">
    <div className="home-content-four-container">
      <div className="bg-area">
        <div className={`title area ${(enterAnimation && (isSmallScreen || pageTabActive === 3)) ? 'title-none-to-show' : ''}`}>
          <img src="https://oss.5rs.me/oss/uploadfe/png/81592822d253a455395630969bf3ed3b.png" />
        </div>
        <div className="text">
          {textList.map((item, index) => <div
            className={`culture culture${index} ${(enterAnimation && (isSmallScreen || pageTabActive === 3)) ? `text-none-to-show${index}` : ''}
                            ${count > 0 && index === 0 ? 'text-three-to-none' : ''} 
                            ${count > 0 && index === 1 ? 'text-two-to-three' : ''} 
                            ${count > 0 && index === 2 ? 'text-one-to-two' : ''}`}
            key={index}
          >
            <img src={item.imgUrl} />
          </div>)}
        </div>
      </div>
      {
        circleList.map((item, index) => <div
          className={`item item${index} ${(enterAnimation && (isSmallScreen || pageTabActive === 3)) ? `circle-none-to-show${index}` : ''}
                        ${count > 0 && index === 0 ? 'one-to-none' : ''} 
                        ${count === 1 && index === 1 ? 'two-to-one' : ''} 
                        ${count === 1 && index === 2 ? 'three-to-two' : ''} 
                        ${count === 1 && index === 3 ? 'four-to-show' : ''} 
                        ${count === 2 && index === 0 ? 'one-to-none-clickthree' : ''} 
                        ${count === 2 && index === 1 ? 'two-to-one-clickthree' : ''} 
                        ${count === 2 && index === 2 ? 'three-to-one' : ''}
                        ${count === 2 && index === 3 ? 'three-to-two' : ''}
                        ${count === 2 && index === 4 ? 'four-to-show' : ''}`}
          onClick={() => onChangeIconCommon(index)}
          key={index}
          onMouseEnter={() => {
            if(isSmallScreen) return;
            clearInterval(interval.current);
          }}
          onMouseOut={() => {
            if(isSmallScreen) return;
            interval.current = setInterval(() => {
              onChangeIcon();
            }, 5000);
          }}
        >
          <img src={item.iconUrl} />
        </div>)
      }
    </div>
  </div>;
}

const IMG_LIST = [
  {
    value: 1,
    className: 'dream',
    iconUrl: 'https://oss.5rs.me/oss/uploadfe/png/e9f14cbc663f7180a57f7b9351d28e60.png'
  },
  {
    value: 2,
    className: 'mission',
    iconUrl: 'https://oss.5rs.me/oss/uploadfe/png/990f62e543353ff33adb9757417c971e.png'
  },
  {
    value: 3,
    className: 'corevalue',
    iconUrl: 'https://oss.5rs.me/oss/uploadfe/png/83978dfd73e990101f0b5b452a2beec2.png'
  }
];
const TEXT_LIST = [
  { value: 1, title: '企业愿景', imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/ae50da522c100f4987a745693b075ff6.png' },
  { value: 2, title: '企业使命', imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/ae24c77fb99eab86ec4585edc4381a33.png' },
  { value: 3, title: '核心价值观', imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/101aab336f7d659a6261ee35ec2c1976.png' }
];