import CryptoJS from 'crypto-js';

const DEFAULT_AES_KEY = '~dcg@rays$2021^&';

/**
 * CryptoJS.AES 加密
 * @param {*} data
 * @param {String} key key
 * @returns {String} 密文
 */
function aesEncrypt(data, key = DEFAULT_AES_KEY) {
  const d = JSON.stringify(data);
  const k = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.AES.encrypt(d, k, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  const ciphertext = encrypted.ciphertext.toString().toUpperCase();
  return ciphertext;
}

/**
 * CryptoJS.AES 解密
 * @param {String} ciphertext 密文
 * @param {String} key key
 * @returns data
 */
function aesDecrypt(ciphertext, key = DEFAULT_AES_KEY) {
  const k = CryptoJS.enc.Utf8.parse(key);
  const encryptedHexStr = CryptoJS.enc.Hex.parse(ciphertext);
  const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decryptedData = CryptoJS.AES.decrypt(encryptedBase64Str, k, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedStr = decryptedData.toString(CryptoJS.enc.Utf8);
  let data = null;
  try {
    data = JSON.parse(decryptedStr);
  } catch(error) {
    console.warn(error);
  }
  return data;
}

export default {
  aesEncrypt,
  aesDecrypt
};