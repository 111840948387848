import React from 'react';
import Map from '../Map/Map';
import './HomePageSeven.scss';

export default function HomePageSeven(props) {
  const { enterAnimation, pageTabActive, isSmallScreen } = props;
  return <div className={`home-content-seven ${isSmallScreen && 'home-content-seven-m'} ${enterAnimation && (pageTabActive === 6 || isSmallScreen) && 'enterAnimation'}`} id="temp7">
    <div className="home-content-seven-container">
      <div className="left circle img-cover">
        <img src="https://oss.5rs.me/oss/uploadfe/png/7bcb84764a5c16fc03aa4d16c017e2f0.png" />
      </div>
      <div className="left">
        <div className="title img-cover">
          <img src="https://oss.5rs.me/oss/uploadfe/png/f1d6499c450301da7786bcb0c76cdc8f.png" />
        </div>
        <div className="success">
          <div>合作出版社遍布全国</div>
          <p><span>34</span>个省级行政区</p>
          <p> <span>300</span>多家出版发行单位</p>
        </div>
        <div className="achievement img-cover">
          <img src="https://oss.5rs.me/oss/uploadfe/png/d1c3e93178a398c0d783fd70a60c91c1.png" />
        </div>
      </div>
      <div className="map">
        <div className="nansha img-cover">
          <img src="https://oss.5rs.me/oss/uploadfe/png/57a8175f1f5a837c8cb718bb0b92b8c4.png" />
        </div>
        <Map />
      </div>
    </div>
  </div>;
}
