import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef, useState } from 'react';
import NewHeader from '@/components/NewHeader';
import { Swiper, Tabs } from 'antd-mobile';
// import SVGA from 'svgaplayerweb';
// import Utils from '@/utils/utils';
import './index.scss';
import classNames from 'classnames';
import Map from './components/Map/Map';
import FooterPage from '@/components/FooterPage';

export default function Home(props) {

  const [currentIndex, setCurrentIndex] = useState(0);
  // const fontSize = 82;
  // const isSmallScreen = true;

  // const startAllAnimation = useRef(false);
  const swiperLeaderTimer = useRef(null);
  const leaderRef = useRef(null);
  // const player = useRef(null);
  // const parser = useRef(null);
  const [course, setCourse] = useState(courseList[0]);
  const [isInit, setIsInit] = useState(false);
  const [tabIndex, setTabIndex] = useState('3');
  const [leafShow, setLeafShow] = useState(false);
  // const [leaveShow, setLeaveShow] = useState(false);
  // const [startLive, setstartLive] = useState(false);
  const [leaderIntroduce, setLeaderIntroduce] = useState(PEOPLE_LIST[0]);
  const [leaderIndex, setLeaderIndex] = useState(0);
  const [leaderArr, setLeaderArr] = useState([0, 1, 2]);
  const leaveShow = false;

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.defaults({
      start: 'top bottom',
      end: '+=99%',
      scrub: true,
      // toggleActions: 'restart pause restart pause',
      scroller: '.Home-asdhja1231 .scroll-box'
    });
    gsap.to('.Home-asdhja1231 .text-box', {
      scaleX: 1
    });
    gsap.to('.Home-asdhja1231 .panel-1 .pull-up', {
      y: -20,
      repeat: -1,
      duration: 1,
      ease: 'none'
    });
    gsap.from('.Home-asdhja1231 .panel-4 .circle-panel-4-1', {
      scrollTrigger: {
        scrub: false,
        trigger: '.Home-asdhja1231 .panel-4'
      },
      opacity: 0,
      x: -126,
      duration: 1,
      ease: 'none'
    });
    gsap.from('.Home-asdhja1231 .panel-6 .circle-panel-6-1', {
      scrollTrigger: {
        scrub: false,
        trigger: '.Home-asdhja1231 .panel-6'
      },
      opacity: 0,
      x: -126,
      duration: 1,
      ease: 'none'
    });

    gsap.to('.Home-asdhja1231 .circle', {
      scrollTrigger: {
        trigger: '.Home-asdhja1231 .panel-1'
      },
      top: 0,
      left: -40,
      opacity: 1,
      width: 250,
      height: 250,
      duration: 0.1
    });
    gsap.to('.Home-asdhja1231 .circle', {
      scrollTrigger: {
        trigger: '.Home-asdhja1231 .panel-2'
      },
      top: 'unset',
      right: 'unset',
      bottom: -139,
      left: -40,
      opacity: 1,
      width: 250,
      height: 250,
      duration: 0.1
    });
    gsap.to('.Home-asdhja1231 .circle', {
      scrollTrigger: {
        trigger: '.Home-asdhja1231 .panel-3'
      },
      top: 'unset',
      right: 'unset',
      bottom: -275,
      xPercent: -50,
      left: '50%',
      opacity: 1,
      width: 442,
      height: 442,
      duration: 0.1
    });
    gsap.to('.Home-asdhja1231 .circle', {
      scrollTrigger: {
        trigger: '.Home-asdhja1231 .panel-4'
      },
      opacity: 0
    });

    gsap.to('.Home-asdhja1231 .animation-circle1', {
      scrollTrigger: {
        scrub: false,
        trigger: '.Home-asdhja1231 .panel-1'
      },
      transformOrigin: 'right bottom',
      scale: 1,
      duration: 0.6
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: '.Home-asdhja1231 .panel-2'
      }
    }).to('.Home-asdhja1231 .animation-circle1', {
      width: 467,
      height: 467,
      top: '50%',
      left: '50%',
      x: '-50%',
      y: '-50%',
      marginTop: 31,
      transformOrigin: 'center center',
      duration: 0.6,
      ease: 'none'
    }).set('.Home-asdhja1231 .animation-circle1', {
      opacity: 0
    }).set('.Home-asdhja1231 .animation-circle2', {
      opacity: 1
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: '.Home-asdhja1231 .panel-3'
      }
    }).to('.Home-asdhja1231 .animation-circle2', {
      width: 1152,
      height: 1152,
      x: 377,
      y: -327,
      duration: 0.6
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: '.Home-asdhja1231 .panel-4'
      }
    }).to('.Home-asdhja1231 .animation-circle2', {
      width: 1152,
      height: 1152,
      top: 'unset',
      bottom: 0,
      xPercent: 0,
      yPercent: 0,
      x: -355,
      y: 666,
      duration: 0.6
    }).set('.Home-asdhja1231 .animation-circle2', {
      opacity: 0
    }).set('.Home-asdhja1231 .animation-circle3', {
      opacity: 1
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: '.Home-asdhja1231 .panel-5'
      }
    }).to('.Home-asdhja1231 .animation-circle3', {
      width: 1152,
      height: 1152,
      top: '50%',
      left: '50%',
      xPercent: -50,
      yPercent: -50,
      x: 0,
      y: 0,
      duration: 0.6,
      onComplete: () => {
        setIsInit(true);
        setLeafShow(false);
        initTree();
      }
    }).set('.Home-asdhja1231 .animation-circle3', {
      opacity: 0
    }).set('.Home-asdhja1231 .animation-circle4', {
      opacity: 1
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: '.Home-asdhja1231 .panel-6'
      }
    }).to('.Home-asdhja1231 .animation-circle4', {
      width: 1800,
      height: 1800,
      top: '100%',
      left: '50%',
      xPercent: -50,
      yPercent: 0,
      marginTop: -60,
      x: 0,
      y: 0,
      duration: 0.6,
      onComplete: () => {
        leaderRef.current.swipeTo(0, true);
        clearTimeout(leaderRef.current);
        setTimeout(() => {
          leaderRef.current.swipeTo(1);
        }, 5000);
      }
    }).set('.Home-asdhja1231 .animation-circle4', {
      opacity: 0
    }).set('.Home-asdhja1231 .animation-circle5', {
      opacity: 1
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: '.Home-asdhja1231 .panel-7'
      }
    }).to('.Home-asdhja1231 .animation-circle5', {
      width: 1800,
      height: 1800,
      top: '50%',
      left: '50%',
      xPercent: -50,
      yPercent: -50,
      marginTop: 0,
      x: 0,
      y: 0,
      duration: 0.6
    }).set('.Home-asdhja1231 .animation-circle5', {
      opacity: 0
    }).set('.Home-asdhja1231 .animation-circle6', {
      opacity: 1
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: '.Home-asdhja1231 .panel-8'
      }
    }).to('.Home-asdhja1231 .animation-circle6', {
      width: 1800,
      height: 1800,
      top: '100%',
      left: '50%',
      xPercent: -50,
      yPercent: 0,
      marginTop: -60,
      x: 0,
      y: 0,
      duration: 0.6
    }).set('.Home-asdhja1231 .animation-circle6', {
      opacity: 0
    }).set('.Home-asdhja1231 .animation-circle7', {
      opacity: 1
    });

    gsap.to('.Home-asdhja1231 .panel-2 .img2', {
      scrollTrigger: {
        scrub: false,
        trigger: '.Home-asdhja1231 .panel-2'
      },
      rotate: 360,
      repeat: -1,
      duration: 20,
      ease: 'none'
    });
    gsap.to('.Home-asdhja1231 .panel-2 .img3', {
      scrollTrigger: {
        scrub: false,
        trigger: '.Home-asdhja1231 .panel-2'
      },
      rotate: -360,
      repeat: -1,
      duration: 20,
      ease: 'none'
    });
    gsap.to('.Home-asdhja1231 .panel-3 .prod-box', {
      scrollTrigger: {
        scrub: false,
        trigger: '.Home-asdhja1231 .panel-3'
      },
      opacity: 1,
      duration: 1,
      ease: 'none'
    });
    return () => {
      clearTimeout(swiperLeaderTimer.current);
    };
  }, []);

  useEffect(() => {
    if(isInit) {
      setLeafShow(false);
      setCourse(courseList[tabIndex - 3]);
      initTree();
    }
  }, [tabIndex, isInit]);

  const initTree = () => {
    gsap.timeline().set('.Home-asdhja1231 .panel-5 .tree', {
      opacity: 0
    }).to('.Home-asdhja1231 .panel-5 .tree', {
      opacity: 1,
      ease: 'none',
      duration: 0.6,
      onComplete: () => setLeafShow(true)
    });
    // player.current = new SVGA.Player('#animate');
    // parser.current = new SVGA.Parser('#animate');
    // parser.current.load('https://cdn.5rs.me/font/shu3zheng.svga?a=2', function(videoItem) {
    //   player.current.loops = 1;
    //   player.current.clearsAfterStop = false;
    //   player.current.setVideoItem(videoItem);
    //   if(isSmallScreen) {
    //     startAllAnimation.current = false;
    //     player.current.startAnimation(false);
    //   }
    // });
    // player.current.onFrame((count) => {
    //   if(count >= 30) {
    //     setLeafShow(true);
    //   }
    // });
  };

  const leaderChange = (i) => {
    clearTimeout(swiperLeaderTimer.current);
    if(i === PEOPLE_LIST.length - 1) {
      swiperLeaderTimer.current = setTimeout(() => {
        leaderRef.current.swipeTo(0);
      }, 5000);
    } else {
      swiperLeaderTimer.current = setTimeout(() => {
        leaderRef.current.swipeTo(i + 1);
      }, 5000);
    }
    setLeaderIndex(i);
    const two = (i + 1) % PEOPLE_LIST.length;
    const three = (i + 2) % PEOPLE_LIST.length;
    setLeaderArr([i, two, three]);
    setLeaderIntroduce(PEOPLE_LIST[i]);
  };

  return <div className="Home-asdhja1231">
    <NewHeader />
    <img className="circle" src="https://oss.5rs.me/oss/uploadfe/png/d85fb8a950f76010f94719e42fbcbe5e.png" />
    <div className="animation-circle animation-circle1" />
    <div className="animation-circle animation-circle2" />
    <div className="animation-circle animation-circle3" />
    <div className="animation-circle animation-circle4" />
    <div className="animation-circle animation-circle5" />
    <div className="animation-circle animation-circle6" />
    <div className="animation-circle animation-circle7" />
    <div className="scroll-box">
      <section className="panel panel-1">
        <Swiper autoplay loop indicator={() => null}>
          {[
            'https://oss.5rs.me/oss/uploadfe/png/9254fc931295cf39b6b714badc29547e.png',
            'https://oss.5rs.me/oss/uploadfe/png/4cfc14f5c0e27c2e6c3b17199a46e10b.png',
            'https://oss.5rs.me/oss/uploadfe/png/9a5b53d4a592f0b9deae276b45580ce8.png'
          ]
            .map((url) => (
              <Swiper.Item key={url}>
                <div className="panel-1-contentImg">
                  <img className="panel-1-contentImg" src={url} loading="lazy" />

                </div>
              </Swiper.Item>))
          }</Swiper>
        <img className="pull-up" src="https://oss.5rs.me/oss/uploadfe/png/1b0979059fcef6dab20c5a4e35632591.png" />

      </section>
      <section className="panel panel-2">
        <div className="content-box-2">
          <img className="img1" src="https://oss.5rs.me/oss/uploadfe/png/60eaa140dfaf610cba0aef60e1c3fe48.png" />
          <img className="img2" src="https://oss.5rs.me/oss/uploadfe/png/4241adde2899a6815b93fc1cf14ba836.png" />
          <div className="left-title">
            <img className="img3" src="https://oss.5rs.me/oss/uploadfe/png/0f297210bf56be319b3dee3c9c037061.png" />
            <img className="img4" src="https://oss.5rs.me/oss/uploadfe/png/ef9efb77f570e7b8678684e9f3af775a.png" />
          </div>
          <div className="content-text">
            <div className="content-title">-从出版而来 为出版奋进-</div>
            <p>数传集团成立于2014年，是一家专注出版行业的智能</p>
            <p>化转型服务商。公司当前在武汉、上海、北京、香</p>
            <p>港设有分公司，在全国15个省、直辖市</p>
            <p>设有本地化服务团队。</p>
          </div>

        </div>
      </section>
      <section className="panel panel-3">
        <i className="circle-panel-3-1" />
        <h3>产品服务</h3>
        <div className="prod-box">
          {/* <img src="https://oss.5rs.me/oss/uploadfe/png/d49fd24e368d545659cee671e406eca0.png" /> */}
          <img src="https://oss.5rs.me/oss/uploadfe/png/e2f0fd8d1f8295d45f7431e7711d272f.png" />
          <img src="https://oss.5rs.me/oss/uploadfe/png/d792a1f6aa2a740c3c3a98f2697cb5bd.png" />
          <img src="https://oss.5rs.me/oss/uploadfe/png/6ccffa8cd07a602e73adc25b18d80cc7.png" />
          {/* <img src="https://oss.5rs.me/oss/uploadfe/png/452e9f53800ffb6fe456066395a65914.png" /> */}
          <img src="https://oss.5rs.me/oss/uploadfe/png/8f7f499a9c98ce1e1b89228b71af2c07.png" />
          <img src="https://oss.5rs.me/oss/uploadfe/png/627ae00a09859ed938eaa7b7261f045e.png" />
        </div>
      </section>
      <section className="panel panel-4">
        <h3>价值文化</h3>
        <i className="circle-panel-4-1" />
        <i className="circle-panel-4-2" />
        <div className="circle-box">
          <Swiper
            loop
            autoplay
            autoplayInterval={5000}
            stuckAtBoundary={false}
            className="carousel-content"
            defaultIndex={currentIndex}
            onIndexChange={i => setCurrentIndex(i)}
          >
            {
              IMG_LIST.map((item, index) => {
                return (<Swiper.Item key={index}>
                  <div className="content-img"><img src={item.url} /></div>
                </Swiper.Item>);
              })
            }
          </Swiper>
        </div>
        <div className="des">
          <h4>{IMG_LIST[currentIndex].title}</h4>
          <i />
          <p className="sub-title">{IMG_LIST[currentIndex].subTitle}</p>
          <p className="content">{IMG_LIST[currentIndex].content}</p>
        </div>
      </section>
      <section className="panel panel-5">
        <h3>发展历程</h3>
        <h4>数传集团</h4>
        <div className="tree">
          <div className="right">
            {course.child.map(item => (item.position % 2 !== 0 ?
              <div key={item.position} className={`courseleft course${item.position} ${item.longText && 'longText-left'} ${item.longlongText && 'longlongText-left'} ${item.longleaf4 && 'longleaf4'} ${item.superlongText && 'superlongText-left'} ${leafShow ? `doing${item.position}` : leaveShow ? `leave` : ''}`}>
                <div className="month"><span>{item.month}</span>月</div>
                <div className={`content ${item.longText && 'long-text'} ${item.longlongText && 'long-long-text'} ${item.superlongText && 'super-long-text'}`}><p>{item.text}</p></div>
              </div> :
              <div key={item.position} className={`courseright course${item.position} ${item.longText ? 'longText-right' : ''} ${item.longlongText ? 'longlongText-right' : ''} ${item.superlongText && 'superlongText-right'} ${leafShow ? `doing${item.position}` : leaveShow ? `leave` : ''}`}>
                <div className={`content ${item.longText && 'long-text'} ${item.longlongText && 'long-long-text'} {item.superlongText && 'super-long-text'}`}><p>{item.text}</p></div>
                <div className="month"><span>{item.month}</span>月</div>
              </div>))}
          </div>
          <img className="tree-bg" src="https://oss.5rs.me/oss/uploadfe/png/27b5c4b2dbd1aac1be62dd5de80bff92.png" />
          {/* <div id="animate" style={{ height: `${6.8 * fontSize}px`, width: `${6.84 * fontSize}px` }} className={`animate ${startLive && 'clipPath'}`} /> */}
          {/* <div id="animate2" style={{ height: `${6.8 * fontSize}px`, width: `${6.84 * fontSize}px`, left: `${3.7 * fontSize}px` }} className={`animate ${!startLive && 'clipPath'}`} /> */}
        </div>
        <div className="tabs">
          <Tabs activeKey={tabIndex} onChange={key => key && setTabIndex(key)}>
            {
              YEAR_LIST.map((item, index) => <Tabs.Tab title={item.value} key={index + ''} />)
            }
          </Tabs>
        </div>
      </section>
      <section className="panel panel-6">
        <i className="circle-panel-6-1" />
        <h3>管理团队</h3>
        <div className="people">
          <Swiper
            // loop
            // autoplay
            // autoplayInterval={5000}
            stuckAtBoundary={false}
            className="carousel-leader"
            defaultIndex={leaderIndex}
            onIndexChange={i => leaderChange(i)}
            ref={leaderRef}
          >
            {
              PEOPLE_LIST.map((item, index) => {
                return (<Swiper.Item key={index} className={classNames((leaderArr.includes(index) || ((index + 1) % PEOPLE_LIST.length === leaderArr[0])) ? 'swiper-show' : '', index === leaderArr[2] ? 'three' : '')}>
                  <div className="content-img">
                    <img src={item.imgUrl} />
                    <span>{item.name}</span>
                  </div>
                </Swiper.Item>);
              })
            }
          </Swiper>
          {leaderIntroduce.introduce ?
            <div className={classNames('people-message')}>
              <div className={`${!leaderIntroduce.introduce && 'mr-top'}`}>{leaderIntroduce.job}</div>
              <p>{leaderIntroduce.introduce}</p>
            </div> :
            <div className={classNames('people-message', 'people-message-2')}>
              <div>数传集团</div>
              <span>{leaderIntroduce.job}</span>
            </div>
          }
        </div>
      </section>
      <section className="panel panel-7">
        <i className="circle-panel-7-1" />
        <h3>业务区域布局</h3>
        <div className="des1">合作出版社遍布全国</div>
        <p className="des2"><i>34</i> 个省级行政区 <i>300</i> 多家出版发行单位</p>
        <div className="nansha">
          <img src="https://oss.5rs.me/oss/uploadfe/png/57a8175f1f5a837c8cb718bb0b92b8c4.png" />
        </div>
        <Map />
        <img src="https://oss.5rs.me/oss/uploadfe/png/d1c3e93178a398c0d783fd70a60c91c1.png" />
      </section>
      <section className="panel panel-8">
        <FooterPage />
      </section>
    </div>
  </div>;
}

const IMG_LIST = [
  {
    value: 1,
    className: 'dream',
    url: 'https://oss.5rs.me/oss/uploadfe/png/e9f14cbc663f7180a57f7b9351d28e60.png',
    title: '企业愿景',
    subTitle: '成为全球出版融合服务引领者',
    content: '数传集团深度融入到全球数字化建设浪潮，在出版融合及文化产业中开展诸多有益的尝试，联动传统出版、教育、互联网与金融等领域的融合创新。推动全国乃至全球出版行业智能化转型升级。'
  },
  {
    value: 2,
    className: 'mission',
    url: 'https://oss.5rs.me/oss/uploadfe/png/990f62e543353ff33adb9757417c971e.png',
    title: '企业使命',
    subTitle: '用科技与创新驱动出版服务',
    content: '数传集团以大数据、云计算、人工智能等新兴技术赋能传统出版业，为出版单位体提供智能化、信息化的出版融合解决方案。以数字化为驱动，保持更好的成长和发展。'
  },
  {
    value: 3,
    className: 'corevalue',
    url: 'https://oss.5rs.me/oss/uploadfe/png/83978dfd73e990101f0b5b452a2beec2.png',
    title: '核心价值观',
    subTitle: '创新变革、卓越质感、结果导向',
    content: '创新是企业持续发展的源动力，质感是产品与服务价值的核心保障，结果是一切行为的终极目标。'
  }
];

const courseList = [
  {
    year: '2023', child: [
      { position: 1, month: 1, text: '荣登2022年武汉市重点税源企业光荣榜' },
      { position: 2, month: 2, text: 'CEO施其明荣获“光谷工匠企业家”' },
      { position: 3, month: 3, text: '担任上海区块链技术协会理事单位' },
      { position: 4, month: 3, text: '担任湖北软件协会理事单位' },
      { position: 5, month: 5, text: '荣获2023年出版融合发展优秀联络员单位' },
      { position: 6, month: 6, text: '荣获第十五届“全国文化企业30强”提名企业' },
      { position: 7, month: 9, text: '湖北省上市后备金文种子企业' },
      { position: 8, month: 9, text: '武汉市现代服务业政策奖补企业' },
      { position: 9, month: 9, text: '2023年武汉东湖高新区独角兽企业' }
    ]
  },
  {
    year: '2022', child: [
      { position: 1, month: 1, text: 'RAYS入选2021-2022年度武汉市优秀高新技术项目' },
      { position: 2, month: 1, text: '2021-2022年度武汉市高新技术企业' },
      { position: 3, month: 2, text: '认定为中国光谷经济发展突出贡献企业' },
      { position: 4, month: 5, text: '入选湖北省2021-2022年度上市后备金种子企业' },
      { position: 5, month: 6, text: '完成D+轮融资' },
      { position: 6, month: 8, text: '荣获工业和信息化部第四批专精特新“小巨人”企业' },
      { position: 7, month: 11, text: '获批出版领域首家教育部工程中心共建单位' },
      { position: 8, month: 11, text: '荣获“湖北省科创新物种——潜在独角兽企业”' },
      { position: 9, month: 12, text: '第二届湖北省文化企业十强' }
    ]
  },
  {
    year: '2021', child: [
      { position: 1, month: 2, text: '获批“国家新闻出版署融合出版智能服务技术与标准重点实验室”', longlongText: true },
      { position: 2, month: 6, text: '武汉未来之星TOP100企业' },
      { position: 3, month: 7, text: '首批武汉雄鹰企业' },
      { position: 4, month: 8, text: '2021年武汉服务业企业百强；武汉首批线上经济重点企业', longText: true },
      { position: 5, month: 9, text: '2021-22年湖北省上市后备“金文种子”；2021年武汉新经济政策奖补企业', longlongText: true },
      { position: 6, month: 10, text: 'RAYS入选全国新闻出版深度融合发展创新案例；湖北省服务业百强', longText: true },
      { position: 7, month: 11, text: '武汉市2021年上市后备金种子企业' },
      { position: 8, month: 12, text: '湖北省2021年专精特新“小巨人”企业；入选《中国文化消费投资发展报告（2021）》', longlongText: true },
      { position: 9, month: 12, text: '荣获“国家新闻出版署科技应用示范单位”' }
    ]
  },
  {
    year: '2020', child: [
      { position: 1, month: 1, text: '完成D轮融资' },
      { position: 2, month: 2, text: '荣获“武汉东湖高新首批科技企业创新案例技术产品清单”', longText: true },
      { position: 4, month: 3, text: '湖北省科学进步奖；RAYS6.0上线，推出书童小睿智能阅读助手', longText: true },
      { position: 6, month: 7, text: '总裁白立华荣获第四届湖北出版政府奖' },
      { position: 7, month: 7, text: 'RAYS 入选国家工信部《中国信息消费助力疫情防控案例》', longText: true },
      { position: 8, month: 9, text: '武汉市软件企业百强' },
      { position: 9, month: 11, text: '首届“湖北文化企业十强”' }
    ]
  },
  {
    year: '2019', child: [
      { position: 1, month: 1, text: '被评为大数据企业20强' },
      { position: 3, month: 4, text: 'RAYS5.0上线，场景化运营纸书' },
      { position: 4, month: 6, text: '中国新闻科技类最高奖王选奖一等奖' },
      { position: 5, month: 7, text: '被评为2019武汉市软件百强企业' },
      { position: 6, month: 8, text: '数传集团西南出版融合运营中心落地重庆' },
      { position: 9, month: 9, text: '获批“湖北省博士后创新实践基地”' }
    ]
  },
  {
    year: '2018', child: [
      { position: 1, month: 1, text: '2017年中国光谷互联网+最具商业价值奖' },
      { position: 2, month: 1, text: '荣获“中国光谷互联网+示范应用项目”' },
      { position: 3, month: 1, text: '获批“国家出版融合数据共享研发基地”' },
      { position: 4, month: 3, text: '入选《2018胡润中国最具投资价值新星企业百强榜》' },
      { position: 5, month: 5, text: '“世界大学女校长论坛”协办单位；获批“湖北省企业技术中心”', longlongText: true },
      { position: 6, month: 7, text: '2017-2018数字出版 优秀展示单位;数传上海子公司正式成立', longText: true },
      { position: 7, month: 11, text: '被评为ISLI国家标准应用技术支持单位' },
      { position: 8, month: 11, text: '获批“湖北省企业技术中心”' },
      { position: 9, month: 11, text: 'RAYS4.0上线，推出“主题书”策略' }
    ]
  },
  {
    year: '2017', child: [
      { position: 1, month: 1, text: '被评为湖北新闻出版广电产业重点企业' },
      { position: 2, month: 2, text: 'RAYS蓝海版本上线' },
      { position: 3, month: 4, text: '2016年度湖北省双创战略团队' },
      { position: 4, month: 5, text: '顺利通过CMMI3级认证' },
      { position: 6, month: 6, text: 'RAYS3.0上线，纸书与读者服务相契合' },
      { position: 7, month: 7, text: '中国数字出版创新论坛数字出版创新奖' },
      { position: 8, month: 9, text: '董事长刘永坚荣获第三届湖北出版政府奖' },
      { position: 9, month: 11, text: '完成C轮融资' }
    ]
  },
  {
    year: '2016', child: [
      { position: 1, month: 4, text: '2015年度湖北省双创战略团队' },
      { position: 2, month: 7, text: '国家双软企业认证' },
      { position: 3, month: 8, text: '荣获2014-2016年推动媒体融合十佳传媒企业；RAYS2.0上线，培养读者扫码习惯', longText: true, longleaf4: true },
      { position: 4, month: 10, text: '资本持续注入完成B轮融资4500万元' },
      { position: 5, month: 10, text: '湖北省唯一入选国家新闻出版广电总局《2016新闻出版业数字化转型升级软件技术服务商推荐名录》的企业', superlongText: true },
      { position: 6, month: 10, text: '数字化转型升级软件技术服务商；武汉市东湖高新区第九批“3551光谷人才计划”', longText: true },
      { position: 7, month: 11, text: '国家新闻出版广电总局首批20个出版融合发展重点实验室建设单位', longText: true },
      { position: 8, month: 11, text: '产品RAYS被评为“武汉市创新产品”' },
      { position: 9, month: 11, text: '湖北省新闻出版广电产业“双百工程”示范企业' }
    ]
  },
  {
    year: '2015', child: [
      { position: 1, month: 8, text: 'RAYS1.0上线，创立“现代纸书”' },
      { position: 4, month: 10, text: '国家高新技术企业认证' },
      { position: 5, month: 11, text: '国家新闻出版广电总局专业数字内容资源知识服务模式试点工作技术支持单位，知识服务与运营技术支持单位第一名', superlongText: true },
      { position: 9, month: 12, text: '获得资本青睐完成A轮融资1500万元' },
      { position: 8, month: 12, text: '北京子公司成立' }
    ]
  },
  {
    year: '2014', child: [
      { position: 1, month: 3, text: '武汉理工数字传播工程有限公司成立' },
      { position: 4, month: 3, text: '湖北省工程技术研究中心' },
      { position: 5, month: 6, text: '“国家数字传播工程创新人才培养基地”' },
      { position: 9, month: 12, text: 'RAYS诞生' }
    ]
  }
];

const YEAR_LIST = [
  { year: '', value: '' },
  { year: '', value: '' },
  { year: '', value: '' },
  { year: '2023', value: 2023 },
  { year: '2022', value: 2022 },
  { year: '2021', value: 2021 },
  { year: '2020', value: 2020 },
  { year: '2019', value: 2019 },
  { year: '2018', value: 2018 },
  { year: '2017', value: 2017 },
  { year: '2016', value: 2016 },
  { year: '2015', value: 2015 },
  { year: '2014', value: 2014 },
  { year: '', value: '' },
  { year: '', value: '' },
  { year: '', value: '' }
];

const PEOPLE_LIST = [
  {
    id: 1,
    name: '刘永坚',
    job: `创始人
董事长`,
    introduce: '武汉理工大学计算机与人工智能学院教授、博士生导师，湖北省科技企业家协会会长，从事出版工作近40年，我国出版融合领域权威专家，国家新闻出版署十三五规划专家组成员，全国新闻出版行业第三批领军人才，湖北新闻出版最高奖——湖北出版政府奖获得者。',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/27f8bb1daa72b0074325bdcd22085251.png'
  },
  {
    id: 2,
    name: '白立华',
    job: `创始人
总裁`,
    introduce: '中宣部“四个一批”人才，王选新闻科学技术一等奖、湖北出版政府奖获得者，兼任中国新闻技术工作者联合会出版融媒体技术分会副秘书长。毕业于武汉大学，原武汉大学出版社科技分社社长，深谙出版融合、知识服务、文化资源数字化领域产学研研究。',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/e923a8aecc417a18895f06af8e511936.png'
  },
  {
    id: 3,
    name: '施其明',
    job: `创始人
CEO执行总裁
        `,
    introduce: '墨尔本大学计算机科学双硕士、澳洲信息科技实验室核心成员、国家新闻出版署出版融合发展（武汉）重点实验室总工程师，曾为新浪体系最年轻的副总裁之一，具有丰富的计算机研发、互联网运营、市场营销实战经验。曾获王选新闻科学技术一等奖，作为技术骨干承担多个国家科技支撑计划、国家重点研发计划。',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/e2c6ae74488bb5134a925e93fdb0bcad.png'
  },
  {
    id: 4,
    name: '温亚莉',
    job: `常务副总裁`,
    introduce: '',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/8dfc6086cd698d097b13fd300aaac9df.png'
  },
  {
    id: 5,
    name: '杨志军',
    job: `总编辑 `,
    introduce: '',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/d36ba9e9dee21b8b3cc01562c10ed81f.png'
  },
  {
    id: 6,
    name: '陈旻麒',
    job: `副总裁`,
    introduce: '',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/45d4b26ed1a8eb280c009d396e72dae0.png'
  },
  {
    id: 7,
    name: '苏洁华',
    job: `副总裁`,
    introduce: '',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/65fba653b49400ba70c0677534b8517a.png'
  },
  {
    id: 8,
    name: '陆欢',
    job: `副总裁`,
    introduce: '',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/90d49816e97ba041215457c95234b778.png'
  }
];
