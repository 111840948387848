import React from 'react';
import './HomePageOne.scss';
import { Carousel } from 'antd';
export default function HomePageOne(props) {
  const { enterAnimation, pageTabActive, isSmallScreen } = props;
  return <div className={`home-content-one ${isSmallScreen && 'home-content-one-m'} ${enterAnimation && (isSmallScreen || pageTabActive === 0) && 'enterAnimation'}`}>
    <div className="home-content-one-container">
      <Carousel
        className="home-content-one-container-carousel"
        autoplay
        dots={false}
        infinite
        slidesToShow={1}
        slidesToScroll={1}
        pauseOnHover={false}
        autoplaySpeed={5000}
      >
        <div>
          <img className="home-content-one-container-bannerImg" src="https://oss.5rs.me/oss/uploadfe/png/d1c3d099c916c2cc9d999d8629b7a508.png" />
        </div>

        <div>
          <img className="home-content-one-container-bannerImg" src="https://oss.5rs.me/oss/uploadfe/png/4dc11d961789a8d5c0df3427e5d111ba.png" />
        </div>
        <div>
          <img className="home-content-one-container-bannerImg" src="https://oss.5rs.me/oss/uploadfe/png/6dfae8128be8cf94e8ae2b43a40d5f69.png" />
        </div>
      </Carousel>

    </div>
  </div>;
}
