import { useSetState } from 'ahooks';
import React, { useEffect } from 'react';
import './HomePageThree.scss';

export default function HomePageThree(props) {
  const { enterAnimation, pageTabActive, isSmallScreen } = props;

  const [state, setState] = useSetState({
    enterEnd: false,
    leaveStart: false
  });

  useEffect(() => {
    if(enterAnimation && pageTabActive === 2) {
      setTimeout(() => {
        setState({
          enterEnd: true
        });
      }, 700);
    } else if(!enterAnimation && (pageTabActive === 3 || pageTabActive === 1)) {
      setTimeout(() => {
        setState({
          enterEnd: false
        });
      }, 700);
    }
  }, [enterAnimation]);
  return <div className={`home-content-three ${isSmallScreen && 'home-content-three-m'} ${(enterAnimation && (isSmallScreen || pageTabActive === 2)) && 'enterAnimation'} ${state.enterEnd && 'enterEnd'}`} id="temp3">
    <div className={`home-content-three-container`}>
      {SERVE_LIST.map((item, index) => <div className={item.className} key={index}>
        <img src={item.coverImg} />
      </div>)}
    </div>
  </div>;
}

const SERVE_LIST = [
  { coverImg: 'https://oss.5rs.me/oss/uploadfe/png/aca49d5912b92639e37eb1c142580c88.png', className: 'bg-area' },
  { coverImg: 'https://oss.5rs.me/oss/uploadfe/png/8f039e78a19df2d8041cd1e795007f5b.png', className: 'bg-area-text' },
  { coverImg: 'https://oss.5rs.me/oss/uploadfe/png/f455a2791bda5f1cc05bddfd83be62f5.png', className: 'nowbook serve' },   //1
  { coverImg: 'https://oss.5rs.me/oss/uploadfe/png/cda26b89a2fcb676655463185d65c200.png', className: 'publish serve' },   //3
  { coverImg: 'https://oss.5rs.me/oss/uploadfe/png/5c4cc62213238df9c8324eb7a41bdbde.png', className: 'edit serve' },   //4
  { coverImg: 'https://oss.5rs.me/oss/uploadfe/png/627ae00a09859ed938eaa7b7261f045e.png', className: 'sale serve' },   //6
  // { coverImg: 'https://oss.5rs.me/oss/uploadfe/png/627ae00a09859ed938eaa7b7261f045e.png', className: 'metaverse serve' },  //7
  { coverImg: 'https://oss.5rs.me/oss/uploadfe/png/428516a953b38d264d1dc79b1d233a71.png', className: 'raysdata serve' }  //2
  // { coverImg: 'https://oss.5rs.me/oss/uploadfe/png/70a2541d306f2305b35cc054be27031f.png', className: 'ruiknown serve' }  //5
];