import React from 'react';
import { Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Utils from '@/utils/utils';
import Home from './home';
import PhoneHome from './responsehome';

import zhCN from 'antd/lib/locale/zh_CN';

import './App.scss';

export const smallPhoneW = 500;
// export const tabletPhoneW = 1200;
export const tabletPhoneW = 900;
function setScreenDirection() {
  if(window.orientation === 180 || window.orientation === 0) {
    // console.log('竖屏');
    window.screenDirection = 'vertical';
  }
  if(window.orientation === 90 || window.orientation === -90) {
    // console.log('横屏');
    window.screenDirection = 'horizontal';
  }
}
function setViewport() {
  const viewport =  document.querySelector('meta[name=viewport]');
  const w = document.documentElement.getBoundingClientRect().width; //获取当前设备的宽度
  if(w <= smallPhoneW) {
    return viewport.content = 'width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no';
  }
  setScreenDirection();
  if(Utils.getScreenWidth() < 1366) {
    viewport.content = 'width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no';
    // viewport.content = 'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=yes';
    document.documentElement.style.fontSize = (screen.width / 3.35) + 'px';
  } else {
    viewport.content = 'width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no';
  }
}
setViewport();
export default class App extends React.PureComponent {
  handleOrientationchange = () => {
    setViewport();
    // this.forceUpdate();
    if(location.pathname === '/') location.reload();
  }

  resize = () => {
    const w = document.documentElement.getBoundingClientRect().width; //获取当前设备的宽度
    if(w <= smallPhoneW) {
      this.setState({ isPc: false });
    } else {
      this.setState({ isPc: true });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      isPc: true
    };
  }

  componentDidMount = () => {
    const keyCodeMap = {
      // 91: true, // command
      61: true,
      107: true, // 数字键盘 +
      109: true, // 数字键盘 -
      173: true, // 火狐 - 号
      187: true, // +
      189: true // -
    };
    // 覆盖ctrl||command + ‘+’/‘-’
    document.onkeydown = function(event) {
      const e = event || window.event;
      const ctrlKey = e.ctrlKey || e.metaKey;
      if(ctrlKey && keyCodeMap[e.keyCode]) {
        e.preventDefault();
      } else if(e.detail) { // Firefox
        event.returnValue = false;
      }
    };
    // 覆盖鼠标滑动
    document.body.addEventListener('wheel', (e) => {
      if(e.ctrlKey) {
        if(e.deltaY < 0) {
          e.preventDefault();
          return false;
        }
        if(e.deltaY > 0) {
          e.preventDefault();
          return false;
        }
      }
    }, { passive: false });
    Utils.setRemUnit();
    window.addEventListener('orientationchange', this.handleOrientationchange);
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('orientationchange', this.handleOrientationchange);
  }

  render() {
    const { isPc } = this.state;
    const Com = isPc ? Home : PhoneHome;
    return (
      <ConfigProvider locale={zhCN}>
        <Switch>
          {this.props.children || <Com {...this.props} />}
        </Switch>
      </ConfigProvider>
    );
  }
}