import React, { useState } from 'react';
import './Map.scss';

export default function Map() {
  const [openId, setOpenId] = useState(0);
  return <div className="map-china">
    {PROVINCE_LIST.map(item => <div className={`province province${item.id}`} key={item.id} onMouseMove={() => setOpenId(item.id)} onMouseOut={() => setOpenId(0)}>
      {openId === item.id &&
                    <div className="tooltip">
                      {item.province}
                    </div>
      }
    </div>)}
  </div>;
}
const PROVINCE_LIST = [
  { id: 1, province: '黑龙江' },
  { id: 2, province: '吉林' },
  { id: 3, province: '辽宁' },
  { id: 4, province: '北京' },
  { id: 5, province: '天津' },
  { id: 6, province: '山东' },
  { id: 7, province: '江苏' },
  { id: 8, province: '上海' },
  { id: 9, province: '浙江' },
  { id: 10, province: '安徽' },
  { id: 11, province: '河南' },
  { id: 12, province: '山西' },
  { id: 13, province: '内蒙古' },
  { id: 14, province: '宁夏' },
  { id: 15, province: '陕西' },
  { id: 16, province: '湖北' },
  { id: 17, province: '江西' },
  { id: 18, province: '福建' },
  { id: 19, province: '广东' },
  { id: 20, province: '湖南' },
  { id: 21, province: '重庆' },
  { id: 22, province: '甘肃' },
  { id: 23, province: '四川' },
  { id: 24, province: '贵州' },
  { id: 25, province: '广西' },
  { id: 26, province: '云南' },

  { id: 27, province: '海南' },
  { id: 28, province: '香港' },
  { id: 29, province: '澳门' },
  { id: 30, province: '台湾' },
  { id: 31, province: '河北' },
  { id: 32, province: '青海' },
  { id: 33, province: '新疆' },
  { id: 34, province: '西藏' }
];
