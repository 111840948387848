import React, { useState, useEffect, useRef } from 'react';
import './HomePageSix.scss';

export default function HomePageSix(props) {

  const { enterAnimation, pageTabActive, isSmallScreen } = props;
  const [leaderIntroduce, setLeaderIntroduce] = useState(PEOPLE_LIST[0]);
  const [peopleId, setPeopleId] = useState(1);
  const timer = useRef(null);

  useEffect(() => {
    banner(peopleId);
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  function banner(id) {
    setPeopleId(id);
    getLeaderIntroduce(id);
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      let index = id < 7 ? id + 1 : 1;
      banner(index);
    }, 5000);
  }

  function getLeaderIntroduce(id) {
    clearTimeout(timer.current);
    let arr = PEOPLE_LIST.filter(v => v.id === id) || [];
    setLeaderIntroduce(arr[0]);
    setPeopleId(id);
  }

  useEffect(() => {
    if((pageTabActive === 5 || isSmallScreen)) {
      banner(peopleId);
    } else {
      getLeaderIntroduce(peopleId);
    }
  }, [pageTabActive, isSmallScreen]);

  return <div className={`home-content-six ${isSmallScreen && 'home-content-six-m'} ${enterAnimation && (pageTabActive === 5 || isSmallScreen) && 'enterAnimation'}`} id="temp6">
    <div className="home-content-six-container">
      <div className="team">
        <img src="https://oss.5rs.me/oss/uploadfe/png/538eecb20d489efda56875620a7bb952.png" />
      </div>
      <div className="people">
        <div className="people-message">
          <div className={`${!leaderIntroduce.introduce && 'mr-top'}`}>{leaderIntroduce.job}</div>
          <p>{leaderIntroduce.introduce}</p>
        </div>
        <div className="people-pic">
          {PEOPLE_LIST.map(item => <div className="people-pic-item" key={item.id}>
            <div
              id={`id${item.id}`}
              className={`item ${peopleId === item.id ? 'active' : ''}`}
              onMouseMove={() => {
                if(isSmallScreen) {
                  banner(item.id);
                } else {
                  getLeaderIntroduce(item.id);
                }
              }}
              onMouseOut={() => {
                if(isSmallScreen) return;
                banner(peopleId);
              }}
            >
              <img src={item.imgUrl} />
              <span>{item.name}</span>
            </div>
            <div className={`inverted ${peopleId === item.id ? 'checked' : ''}`} />
          </div>)}
        </div>
      </div>
    </div>
  </div>;
}

const PEOPLE_LIST = [
  {
    id: 1,
    name: '刘永坚',
    job: `创始人
董事长`,
    introduce: '武汉理工大学计算机与人工智能学院教授、博士生导师，湖北省科技企业家协会会长，从事出版工作近40年，我国出版融合领域权威专家，国家新闻出版署十三五规划专家组成员，全国新闻出版行业第三批领军人才，湖北新闻出版最高奖——湖北出版政府奖获得者。',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/27f8bb1daa72b0074325bdcd22085251.png'
  },
  {
    id: 2,
    name: '白立华',
    job: `创始人
总裁`,
    introduce: '中宣部“四个一批”人才，王选新闻科学技术一等奖、湖北出版政府奖获得者，兼任中国新闻技术工作者联合会出版融媒体技术分会副秘书长。毕业于武汉大学，原武汉大学出版社科技分社社长，深谙出版融合、知识服务、文化资源数字化领域产学研研究。',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/e923a8aecc417a18895f06af8e511936.png'
  },
  {
    id: 3,
    name: '施其明',
    job: `创始人
CEO执行总裁
        `,
    introduce: '墨尔本大学计算机科学双硕士、澳洲信息科技实验室核心成员、国家新闻出版署出版融合发展（武汉）重点实验室总工程师，曾为新浪体系最年轻的副总裁之一，具有丰富的计算机研发、互联网运营、市场营销实战经验。曾获王选新闻科学技术一等奖，作为技术骨干承担多个国家科技支撑计划、国家重点研发计划。',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/e2c6ae74488bb5134a925e93fdb0bcad.png'
  },
  {
    id: 4,
    name: '温亚莉',
    job: `数传集团
常务副总裁 `,
    introduce: '',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/8dfc6086cd698d097b13fd300aaac9df.png'
  },
  {
    id: 5,
    name: '杨志军',
    job: `数传集团
总编辑 `,
    introduce: '',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/d36ba9e9dee21b8b3cc01562c10ed81f.png'
  },
  {
    id: 6,
    name: '陈旻麒',
    job: `数传集团
副总裁
        `,
    introduce: '',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/45d4b26ed1a8eb280c009d396e72dae0.png'
  },
  {
    id: 7,
    name: '苏洁华',
    job: `数传集团
副总裁
        `,
    introduce: '',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/65fba653b49400ba70c0677534b8517a.png'
  },
  {
    id: 8,
    name: '陆欢',
    job: `数传集团
副总裁
        `,
    introduce: '',
    imgUrl: 'https://oss.5rs.me/oss/uploadfe/png/90d49816e97ba041215457c95234b778.png'
  }
];